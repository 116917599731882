//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2021 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

$advanced-columns--label-size: 20%
$advanced-columns--operator-size: 20%
$advanced-columns--values-size: 25%
$advanced-columns--values-and-operator-size: 45%
$advanced-columns--close-icon-size: 50px
$advanced-columns--grid-gap: 10px

.advanced-columns--container
  @extend %columns--container
  padding: 1rem
  max-height: 50vh
  overflow-y: auto
  position: relative
  margin: 0 0 10px 0

  &.collapsed
    display: none

  .advanced-columns--close
    position: absolute
    top: 0.75rem
    right: 0.75rem
    width: 1rem
    z-index: 2

.advanced-columns--columns
  list-style-type: none
  margin: 20px 0 0 0

  > .advanced-columns--column
    display: grid
    // Filters will not span the whole width,
    // but have an orientation to the left side
    grid-template-columns: $advanced-columns--label-size $advanced-columns--operator-size $advanced-columns--values-size $advanced-columns--close-icon-size
    grid-gap: $advanced-columns--grid-gap
    align-items: center
    margin-bottom: 10px

    &.--without-operator
      grid-template-columns: $advanced-columns--label-size calc(#{$advanced-columns--values-and-operator-size} + #{$advanced-columns--grid-gap}) $advanced-columns--close-icon-size

  .advanced-columns--column-name,
  .advanced-columns--add-column-label
    @include text-shortener
    margin: auto 0

  .advanced-columns--column-value,
  .advanced-columns--column-operator
    .advanced-columns--select,
    .advanced-columns--number-field
      @include form--input-field-mixin--small
      @include form--input-field-mixin--narrow
      flex: 0 0 auto

.advanced-columns--add-column
  display: grid
  grid-template-columns: $advanced-columns--label-size calc(#{$advanced-columns--values-and-operator-size} + #{$advanced-columns--grid-gap}) $advanced-columns--close-icon-size
  grid-gap: $advanced-columns--grid-gap

// The type="text" is required to be more specific
.advanced-columns--text-field[type="text"],
.advanced-columns--date-field[type="text"]
  @extend .form--text-field

  &:required
    box-shadow: none

.advanced-columns--select
  @extend .form--select

.advanced-columns--affix
  @extend .form--field-affix
  @include form--field-affix-mixin--transparent
  font-size: 0.9rem

.advanced-columns--tooltip-trigger[data-tooltip]
  @extend .advanced-columns--affix, .tooltip--right

.advanced-columns--remove-column
  text-align: right

  a
    display: block

    &:hover
      text-decoration: none

.advanced-columns--remove-column-icon
  @extend .icon-close, .icon4

.advanced-columns--add-column-label-icon
  @extend .icon-add, .icon4

.advanced-columns--add-column-info
  @include grid-content()
  @include grid-visible-overflow

.advanced-columns--spacer
  border-top: 1px solid $columns--border-color
  height: 1px
  margin: 0.75rem 0

.advanced-columns--spacer,
.advanced-columns--column
  &.hidden
    display: none !important

.work-packages-embedded-view--container .advanced-columns--container
  margin: 0 0 1rem 0

@include breakpoint(680px down)
  .advanced-columns--columns
    .advanced-columns--column
      grid-gap: 0 10px

    .advanced-columns--column-name,
    .advanced-columns--add-column-label,
    .advanced-columns--add-column-value
      // Span over entire width
      grid-column: 1 / -1

    .advanced-columns--remove-column
      // Align at the end of the container
      grid-column: -1

    .advanced-columns--column-operator,
    .advanced-columns--column-value
      grid-column: span 2
      order: 2

      .advanced-columns--text-field,
      .advanced-columns--select,
      .advanced-columns--number-field
        width: 100%
